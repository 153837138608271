"use client"

import { ArrowDownAZ, ArrowDownWideNarrow, Banknote, Users } from "lucide-react"
import { motion } from "framer-motion"
import { useRouter, useSearchParams } from "next/navigation"
import { useState, useEffect } from "react"

const SORT_OPTIONS = [
  {
    value: "newest",
    label: "Latest Lists",
    icon: ArrowDownWideNarrow,
  },
  {
    value: "price_desc",
    label: "Highest Price",
    icon: Banknote,
  },
  {
    value: "leads_desc",
    label: "Most Leads",
    icon: Users,
  },
  {
    value: "sold_desc",
    label: "Best Selling",
    icon: ArrowDownAZ,
  },
] as const

export default function ListFilters() {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [sortBy, setSortBy] = useState(searchParams?.get("sort") || "newest")

  useEffect(() => {
    setSortBy(searchParams?.get("sort") || "newest")
  }, [searchParams])

  const handleSort = (value: string) => {
    setSortBy(value)
    
    // Use searchParams.toString() to get all current parameters
    const params = new URLSearchParams(searchParams?.toString() || "")
    
    // Update or remove the sort parameter
    if (value === "newest") {
      params.delete("sort")
    } else {
      params.set("sort", value)
    }
    
    // Log what's happening (only in development)
    if (process.env.NODE_ENV === 'development') {
      console.log("Sort changed to:", value);
      console.log("URL params:", params.toString());
      console.log("Current searchParams:", Object.fromEntries(searchParams?.entries() || []));
    }
    
    // Use client-side navigation without refreshing the page
    router.push(`/?${params.toString()}`, { scroll: false });
  }

  return (
    <div className="flex items-center justify-end gap-2 bg-white p-3 rounded-xl border border-gray-200 shadow-sm">
      <span className="text-sm text-gray-500 mr-2">Sort by:</span>
      <div className="flex gap-2">
        {SORT_OPTIONS.map((option) => {
          const Icon = option.icon
          return (
            <motion.button
              key={option.value}
              onClick={() => handleSort(option.value)}
              className={`inline-flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm font-medium transition-all
                ${
                  sortBy === option.value
                    ? "bg-blue-50 text-blue-700 border border-blue-200"
                    : "text-gray-600 hover:bg-gray-50 border border-gray-200"
                }`}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Icon className="w-4 h-4" />
              {option.label}
            </motion.button>
          )
        })}
      </div>
    </div>
  )
} 