"use client"

import type React from "react"

import SearchBar from "@/components/SearchBar"
import PurchaseSuccessHandler from "@/components/PurchaseSuccessHandler"
import { Inter } from "next/font/google"
import { useQuery } from "convex/react"
import { api } from "@/convex/_generated/api"
import { useState, useCallback, useMemo, Suspense } from "react"
import {
  ChevronLeft,
  ChevronRight,
  Database,
  Mail,
  Phone,
  HelpCircle,
  Shield,
  Award,
  Zap,
  Users,
  Star,
} from "lucide-react"
import { Button } from "@/components/ui/button"
import EventCard from "@/components/EventCard"
import { useSearchParams } from "next/navigation"
import { LoadingSpinner } from "@/components/LoadingSpinner"
import ListFilters from "@/components/ListFilters"
import { motion } from "framer-motion"

// Add custom animation classes
import { useEffect } from "react"

const inter = Inter({ subsets: ["latin"] })
const ITEMS_PER_PAGE = 20

// Add this to your HomePageContent function
function HomePageContent() {
  // Rest of your component...
  const searchParams = useSearchParams()
  const [currentPage, setCurrentPage] = useState(1)

  const searchTerm = searchParams?.get("q") || undefined
  const category = searchParams?.get("category") || undefined
  const sortBy = searchParams?.get("sort") || undefined

  const searchConfig = useMemo(
    () => ({
      searchTerm: searchTerm || undefined,
      category: category || undefined,
      page: currentPage,
      pageSize: ITEMS_PER_PAGE,
      sortBy: sortBy || undefined,
    }),
    [searchTerm, category, currentPage, sortBy],
  )

  const result = useQuery(api.events.getWithFilters, searchConfig)

  // Only log in development
  if (process.env.NODE_ENV === "development") {
    console.log("Search config:", searchConfig, "Result:", result)
  }

  useEffect(() => {
    if (result === undefined && process.env.NODE_ENV === "development") {
      console.log("Loading data or query error occurred")
    }
  }, [result])

  const handlePageChange = useCallback(
    (newPage: number) => {
      const lastPageChange = sessionStorage.getItem("lastPageChange")
      const now = Date.now()
      if (lastPageChange && now - Number.parseInt(lastPageChange, 10) < 1000) {
        return
      }
      sessionStorage.setItem("lastPageChange", now.toString())
      const maxPage = result?.totalPages || 1
      const safeNewPage = Math.min(Math.max(1, newPage), maxPage)
      setCurrentPage(safeNewPage)
    },
    [result?.totalPages],
  )

  useEffect(() => {
    setCurrentPage(1)
  }, [searchParams?.get("q"), searchParams?.get("category")])

  const [showContact, setShowContact] = useState(false)
  const [honeypotValue, setHoneypotValue] = useState("")

  const handleRevealContact = (e: React.FormEvent) => {
    e.preventDefault()
    if (honeypotValue) {
      console.log("Bot detected")
      return
    }
    setShowContact(true)
  }

  // Temporary debug code - REMOVE AFTER DEBUGGING
  const dbDebug = useQuery(api.events.debugDatabase)
  useEffect(() => {
    if (dbDebug) {
      console.log("Database debug:", dbDebug)
    }
  }, [dbDebug])

  // Add this if needed for debugging in development
  useEffect(() => {
    if (process.env.NODE_ENV === "development" && result) {
      console.log("Query result:", {
        total: result.totalCount,
        pageSize: ITEMS_PER_PAGE,
        pages: result.totalPages,
        items: result.events?.length,
        sortBy: sortBy,
      })
    }
  }, [result, sortBy, ITEMS_PER_PAGE])

  // Stats badges data
  const statsBadges = [
    { icon: <Award className="w-4 h-4 sm:w-5 sm:h-5 text-blue-600" />, text: "500M+ Leads" },
    { icon: <Users className="w-4 h-4 sm:w-5 sm:h-5 text-blue-600" />, text: "10+ Data Sources" },
    { icon: <Star className="w-4 h-4 sm:w-5 sm:h-5 text-blue-600" />, text: "98% Satisfaction" },
  ]

  return (
    <>
      {/* Hero Section with Title and Search */}
      <div className="bg-gradient-to-b from-white via-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 md:py-8 lg:py-12">
          <div className="text-center space-y-4 lg:space-y-8 max-w-6xl mx-auto animate-fade-in">
            {/* Stats badges - single on mobile, three on lg */}
            <div className="inline-flex flex-wrap justify-center gap-2 lg:gap-4">
              {/* Mobile badge (only first one) */}
              <motion.div
                className="lg:hidden flex items-center gap-2 px-4 py-2 bg-white border border-gray-200 text-gray-800 rounded-full shadow-sm"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                <span className="bg-blue-50 p-1.5 rounded-full">{statsBadges[0].icon}</span>
                <span className="font-medium text-sm sm:text-base md:text-lg">{statsBadges[0].text}</span>
              </motion.div>

              {/* Desktop badges (all three) */}
              {statsBadges.map((badge, index) => (
                <motion.div
                  key={index}
                  className="hidden lg:flex items-center gap-2 px-4 py-2 bg-white border border-gray-200 text-gray-800 rounded-full shadow-sm"
                  whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 + index * 0.1, duration: 0.5 }}
                >
                  <span className="bg-blue-50 p-1.5 rounded-full">{badge.icon}</span>
                  <span className="font-medium text-sm sm:text-base md:text-lg lg:text-xl">{badge.text}</span>
                </motion.div>
              ))}
            </div>

            <div className="space-y-3 lg:space-y-6">
              <motion.h1
                className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold tracking-tight text-gray-900 leading-tight"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <motion.span
                  className="block bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent"
                  initial={{ y: -20 }}
                  animate={{ y: 0 }}
                  transition={{ delay: 0.2, duration: 0.5, type: "spring" }}
                >
                  Buy and Sell
                </motion.span>
                <motion.span
                  className="block text-blue-600"
                  initial={{ y: 20 }}
                  animate={{ y: 0 }}
                  transition={{ delay: 0.3, duration: 0.5, type: "spring" }}
                >
                  Verified Lead Lists
                </motion.span>
              </motion.h1>

              <motion.p
                className="text-base sm:text-lg md:text-xl lg:text-2xl text-gray-600 max-w-4xl mx-auto leading-relaxed font-light"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
              >
                The fully open marketplace for <span className="text-blue-600 font-medium"> B2B</span>
                <span className="text-blue-600 font-medium"> leads</span>.
              </motion.p>
            </div>

            <motion.div
              className="flex flex-wrap justify-center gap-2 sm:gap-3 lg:gap-4 pt-3 lg:pt-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              {[
                { icon: <Mail className="w-3.5 h-3.5 lg:w-4 lg:h-4 text-blue-600" />, text: "Contact Data" },
                { icon: <Shield className="w-4 h-4 lg:w-5 lg:h-5 text-blue-600" />, text: "Verified Sellers" },
                { icon: <Zap className="w-4 h-4 lg:w-5 lg:h-5 text-blue-600" />, text: "Instant Access" },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="flex items-center gap-1.5 sm:gap-2 px-3 sm:px-4 py-1.5 sm:py-2.5 lg:px-6 lg:py-3 bg-white text-gray-800 rounded-xl shadow-sm border border-gray-200 hover:border-blue-200 hover:shadow-md"
                  whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.6 + index * 0.1, duration: 0.5 }}
                >
                  <div className="bg-blue-50 p-1 lg:p-2 rounded-full">{item.icon}</div>
                  <span className="font-medium text-xs sm:text-sm lg:text-lg">{item.text}</span>
                </motion.div>
              ))}
            </motion.div>

            <motion.div
              className="text-sm sm:text-base text-gray-600 mt-3 text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 0.5 }}
            >
              <motion.span
                className="inline-flex items-center gap-1.5 px-3 py-1.5 bg-green-50 rounded-full border border-green-100"
                whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
              >
                <svg className="w-4 h-4 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <span className="font-medium">Free to list</span> • 10% fee on sales
              </motion.span>
            </motion.div>
          </div>

          <div className="mt-4 lg:mt-6 max-w-4xl mx-auto">
            <SearchBar />
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="space-y-6">
          {/* Filters Section */}
          <ListFilters />

          {/* Results Section */}
          <div className="bg-white rounded-2xl border border-gray-200 shadow-sm p-6 sm:p-8">
            <div className="flex flex-col space-y-1 border-b border-gray-100 pb-6">
              <h2 className="text-2xl font-semibold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                Find a Lead List
              </h2>
              <div className="flex items-center gap-2">
                <span className="text-sm font-medium text-gray-600">{result?.totalCount || 0} available lists</span>
                {searchTerm && (
                  <>
                    <span className="text-gray-400">•</span>
                    <span className="text-sm text-gray-600">
                      matching "<span className="font-medium text-blue-600">{searchTerm}</span>"
                    </span>
                  </>
                )}
              </div>
            </div>

            {!result ? (
              <div className="flex justify-center py-16">
                <LoadingSpinner />
              </div>
            ) : result.events && result.events.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
                {result.events.map((event) => (
                  <EventCard key={event._id} eventId={event._id} />
                ))}
              </div>
            ) : (
              <div className="text-center py-16 bg-gray-50 rounded-xl border border-gray-100">
                <Database className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  {result.error ? "Error loading lead lists" : "No matching lead lists found"}
                </h3>
                <p className="text-gray-500">
                  {result.error
                    ? "Please try again later or contact support if the issue persists."
                    : "Try broadening your search criteria or using different categories"}
                </p>
              </div>
            )}

            {result && result.totalPages > 1 && (
              <div className="flex justify-center gap-3 pt-8">
                <Button
                  variant="outline"
                  onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                  disabled={currentPage === 1}
                  className="flex items-center gap-2 bg-white"
                >
                  <ChevronLeft className="w-4 h-4" />
                  Previous
                </Button>

                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-600">
                    Page {currentPage} of {result.totalPages}
                  </span>
                </div>

                <Button
                  variant="outline"
                  onClick={() => handlePageChange(Math.min(result.totalPages, currentPage + 1))}
                  disabled={currentPage === result.totalPages}
                  className="flex items-center gap-2 bg-white"
                >
                  Next
                  <ChevronRight className="w-4 h-4" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="bg-white border-t border-gray-200 mt-16">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="bg-blue-50 rounded-2xl p-8 border border-blue-100 shadow-sm">
            <div className="text-center space-y-4">
              <div className="inline-flex items-center justify-center w-12 h-12 bg-blue-100 rounded-full text-blue-600 mb-2">
                <HelpCircle className="w-6 h-6" />
              </div>

              <h2 className="text-2xl font-bold text-gray-900">Have questions?</h2>

              <p className="text-gray-600 max-w-lg mx-auto">
                We're here to assist you with any questions about buying or selling lead lists.
              </p>

              {!showContact ? (
                <form onSubmit={handleRevealContact} className="pt-4">
                  <div className="opacity-0 absolute -z-10 left-[-9999px]">
                    <label htmlFor="website">Website</label>
                    <input
                      type="text"
                      id="website"
                      name="website"
                      value={honeypotValue}
                      onChange={(e) => setHoneypotValue(e.target.value)}
                      tabIndex={-1}
                      autoComplete="off"
                    />
                  </div>

                  <Button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white">
                    Show Contact Information
                  </Button>
                </form>
              ) : (
                <div className="pt-4 space-y-4">
                  <a
                    href="mailto:toni@getlists.io"
                    className="inline-flex items-center justify-center gap-2 text-blue-600 hover:text-blue-700 transition-colors"
                  >
                    <Mail className="w-5 h-5" />
                    toni@getlists.io
                  </a>
                  <div className="h-px w-16 bg-gray-200 mx-auto"></div>
                  <a
                    href="https://chat.whatsapp.com/KmJhpCsatWhClrMV1fIunl"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center gap-2 text-blue-600 hover:text-blue-700 transition-colors"
                  >
                    <Phone className="w-5 h-5" />
                    WhatsApp
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default function HomePage() {
  return (
    <div className={`min-h-screen bg-gray-50 ${inter.className}`}>
      <Suspense
        fallback={
          <div className="flex justify-center py-16">
            <LoadingSpinner />
          </div>
        }
      >
        <PurchaseSuccessHandler />
        <HomePageContent />
      </Suspense>
    </div>
  )
}

