import { Spinner } from "@/components/ui/spinner";

export function LoadingSpinner() {
  return (
    <div className="flex items-center justify-center min-h-[400px]">
      <div className="flex flex-col items-center gap-4">
        <Spinner className="lg" />
        <p className="text-sm text-gray-500">Loading...</p>
      </div>
    </div>
  );
} 