"use client"

import type React from "react"

import { Search } from "lucide-react"
import { useRouter, useSearchParams } from "next/navigation"
import { useState, useEffect, useRef } from "react"
import { CATEGORIES } from "@/lib/constants"
import { motion } from "framer-motion"

export default function SearchBar() {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [query, setQuery] = useState(searchParams?.get("q") || "")
  const [selectedCategory, setSelectedCategory] = useState(searchParams?.get("category") || "all")
  const [searchCount, setSearchCount] = useState(0)
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null)

  // Update local state when URL params change
  useEffect(() => {
    setQuery(searchParams?.get("q") || "")
    setSelectedCategory(searchParams?.get("category") || "all")
  }, [searchParams])

  // Track search count in session storage
  useEffect(() => {
    const storedCount = sessionStorage.getItem("searchCount")
    if (storedCount) {
      setSearchCount(Number.parseInt(storedCount, 10))
    }
  }, [])

  const updateSearchCount = () => {
    const newCount = searchCount + 1
    setSearchCount(newCount)
    sessionStorage.setItem("searchCount", newCount.toString())
  }

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault()

    if (query.trim() && query.trim().length < 2) {
      return
    }

    if (searchCount > 20) {
      const lastSearch = sessionStorage.getItem("lastSearchTime")
      const now = Date.now()
      if (lastSearch && now - Number.parseInt(lastSearch, 10) < 5000) {
        return
      }
    }

    sessionStorage.setItem("lastSearchTime", Date.now().toString())
    updateSearchCount()

    const params = new URLSearchParams()
    if (query.trim()) params.set("q", query.trim())
    if (selectedCategory !== "all") params.set("category", selectedCategory)
    // Add scroll: false to prevent page jump
    router.push(`/?${params.toString()}`, { scroll: false })
  }

  const handleInputChange = (value: string) => {
    setQuery(value)

    // Debounce search for typing
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current)
    }

    // Only trigger search after user stops typing for 500ms
    debounceTimerRef.current = setTimeout(() => {
      if (value.trim() && value.trim().length >= 2) {
        const params = new URLSearchParams(searchParams?.toString() || "")
        params.set("q", value.trim())
        if (selectedCategory !== "all") params.set("category", selectedCategory)
        // Add scroll: false to prevent page jump
        router.push(`/?${params.toString()}`, { scroll: false })
        updateSearchCount()
      }
    }, 500)
  }

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category)
    updateSearchCount()

    const params = new URLSearchParams(searchParams || {})
    if (category === "all") {
      params.delete("category")
    } else {
      params.set("category", category)
    }
    if (query) params.set("q", query)
    // Add scroll: false and shallow: true to prevent page jump
    router.push(`/?${params.toString()}`, { scroll: false })
  }

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15,
      },
    },
  }

  return (
    <motion.div className="w-full" variants={containerVariants} initial="hidden" animate="visible">
      <form onSubmit={handleSearch} className="space-y-5">
        {/* Search Input - Modernized design */}
        <motion.div className="flex flex-col sm:flex-row gap-3 lg:gap-5" variants={itemVariants}>
          <div className="relative flex-1 group">
            <Search className="absolute left-4 lg:left-6 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5 lg:w-6 lg:h-6 group-hover:text-blue-500 transition-colors" />
            <input
              type="text"
              value={query}
              onChange={(e) => handleInputChange(e.target.value)}
              placeholder="Search lead lists..."
              className="w-full h-12 pl-12 pr-4 lg:h-16 lg:pl-16 lg:pr-6 bg-white rounded-full border border-gray-200 
                       focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                       transition-all shadow-sm hover:shadow text-base lg:text-xl"
              maxLength={50}
            />
          </div>
          <motion.button
            type="submit"
            className="h-12 lg:h-16 bg-gradient-to-r from-blue-600 to-blue-700 text-white px-8 lg:px-12 rounded-full font-medium 
                     hover:from-blue-700 hover:to-blue-800 transition-all shadow-sm hover:shadow
                     flex items-center justify-center gap-2 sm:w-auto w-full text-base lg:text-lg"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <Search className="w-5 h-5 lg:w-6 lg:h-6" />
            <span>Search</span>
          </motion.button>
        </motion.div>

        {/* Categories - Improved design and scrolling */}
        <div className="relative">
          {/* Gradient fade overlay to indicate more content */}
          <div className="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-gray-50 to-transparent pointer-events-none" />
          
          <motion.div 
            className="overflow-x-auto pb-2 -mx-2 px-2 scrollbar-hide" 
            variants={itemVariants}
            initial={{ x: 0 }}
            animate={{ 
              x: [0, 10, 0],
              transition: {
                duration: 1.5,
                repeat: 2,
                repeatDelay: 1,
              }
            }}
          >
            <div className="flex flex-nowrap gap-2 min-w-max pr-20"> {/* Added right padding to account for gradient */}
              <motion.button
                type="button"
                onClick={() => handleCategoryChange("all")}
                className={`px-5 py-2 lg:px-6 lg:py-3 rounded-full text-sm lg:text-base font-medium transition-all whitespace-nowrap ${
                  selectedCategory === "all"
                    ? "bg-blue-50 text-blue-700 border border-blue-200"
                    : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-200 hover:border-blue-200"
                }`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                All Categories
              </motion.button>
              {CATEGORIES.map((category) => (
                <motion.button
                  key={category}
                  type="button"
                  onClick={() => handleCategoryChange(category)}
                  className={`px-5 py-2 lg:px-6 lg:py-3 rounded-full text-sm lg:text-base font-medium transition-all whitespace-nowrap ${
                    selectedCategory === category
                      ? "bg-blue-50 text-blue-700 border border-blue-200"
                      : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-200 hover:border-blue-200"
                  }`}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {category}
                </motion.button>
              ))}
            </div>
          </motion.div>
        </div>
      </form>
    </motion.div>
  )
}

