"use client";

import { useEffect } from 'react';
import { useSearchParams, useRouter } from 'next/navigation';
import { useToast } from '@/hooks/use-toast';

export default function PurchaseSuccessHandler() {
  const searchParams = useSearchParams();
  const router = useRouter();
  const { toast } = useToast();

  useEffect(() => {
    const cancelled = searchParams?.get('cancelled');
    const success = searchParams?.get('success');
    
    if (cancelled === 'true') {
      toast({
        title: "Purchase Cancelled",
        description: "Your purchase was cancelled. No payment was processed.",
        variant: "default",
      });
      // Remove query params but keep the path
      router.replace(window.location.pathname);
    } else if (success === 'true') {
      toast({
        title: "Purchase Successful",
        description: "Thank you for your purchase! You now have access to the data.",
        variant: "default",
      });
      // Remove query params but keep the path
      router.replace(window.location.pathname);
    }
  }, [searchParams, toast, router]);

  return null;
} 