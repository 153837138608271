export const CATEGORIES = [
  "Investors",
  "Real Estate",
  "CEOs",
  "Finance",
  "Linkedin",
  "X/Twitter",
  "Small Business Owners",
  "E-commerce",
  "Influencers",
  "Apollo",
  "Other"
] as const;

export type Category = (typeof CATEGORIES)[number]; 